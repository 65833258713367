$(function () {
    initProductOverview();
    initDetail();
});

// Polyfills
if (!NodeList.prototype.forEach) {
    NodeList.prototype.forEach = function (fn, scope) {
        for (var i = 0, len = this.length; i < len; ++i) {
            fn.call(scope, this[i], i, this);
        }
    }
}

if (!Array.prototype.forEach) {
    Array.prototype.forEach = function (fn, scope) {
        for (var i = 0, len = this.length; i < len; ++i) {
            fn.call(scope, this[i], i, this);
        }
    }
}

// https://tc39.github.io/ecma262/#sec-array.prototype.includes
if (!Array.prototype.includes) {
    Object.defineProperty(Array.prototype, 'includes', {
        value: function (searchElement, fromIndex) {

            // 1. Let O be ? ToObject(this value).
            if (this == null) {
                throw new TypeError('"this" is null or not defined');
            }

            var o = Object(this);

            // 2. Let len be ? ToLength(? Get(O, "length")).
            var len = o.length >>> 0;

            // 3. If len is 0, return false.
            if (len === 0) {
                return false;
            }

            // 4. Let n be ? ToInteger(fromIndex).
            //    (If fromIndex is undefined, this step produces the value 0.)
            var n = fromIndex | 0;

            // 5. If n ≥ 0, then
            //  a. Let k be n.
            // 6. Else n < 0,
            //  a. Let k be len + n.
            //  b. If k < 0, let k be 0.
            var k = Math.max(n >= 0 ? n : len - Math.abs(n), 0);

            // 7. Repeat, while k < len
            while (k < len) {
                // a. Let elementK be the result of ? Get(O, ! ToString(k)).
                // b. If SameValueZero(searchElement, elementK) is true, return true.
                // c. Increase k by 1.
                // NOTE: === provides the correct "SameValueZero" comparison needed here.
                if (o[k] === searchElement) {
                    return true;
                }
                k++;
            }

            // 8. Return false
            return false;
        }
    });
}

function initProductOverview() {
    if ($('#products').length > 0) {
        initShoppingCart();

        var $button = $('#products .page-content .read-more');
        if ($('#products .page-content .text p').length > 1) {
            var height = $('#products .page-content .text').outerHeight();

            $('#products .page-content .text').css({
                height: $('#products .page-content .text').children('p').first().outerHeight()
            });

            $button.on('click', function () {
                $('#products .page-content .text').toggleClass('active');

                if ($('#products .page-content .text').hasClass('active')) {
                    $button.find('i').removeClass('icon-plus').addClass('icon-minus');
                    $button.find('span').text('Lees minder');

                    $('#products .page-content .text').css({
                        height: height
                    });
                } else {
                    $button.find('i').removeClass('icon-minus').addClass('icon-plus');
                    $button.find('span').text('Lees meer');

                    $('#products .page-content .text').css({
                        height: $('#products .page-content .text').children('p').first().outerHeight()
                    });
                }
            });
        } else {
            $button.hide();
        }

        $('.product').matchHeight();
    }
}

function initDetail() {
    if ($('#product').length > 0) {
        initDetailGallery();
        initShoppingCart();
        initImageLightbox();
        initVariants();
    }
}

function initDetailGallery() {
    if ($('#product .gallery').length > 0) {
        $('#product .gallery .images').slick({
            slidesToShow: 1,
            fade: true,
            arrows: false,
        });

        if ($('#product .gallery .images .slide').length < 2) {
            $('#product .gallery .nav').hide();
        } else {
            $('#product .gallery .nav').slick({
                mobileFirst: true,
                slidesToShow: 2,
                infinite: false,
                focusOnSelect: true,
                asNavFor: '#product .gallery .images',
                arrows: false,
                responsive: [
                    {
                        breakpoint: 992,
                        settings: {
                            slidesToShow: 4
                        }
                    }
                ]
            });
        }
    }
}

function initShoppingCart() {
    var options = '';

    // $('.values').each(function (i, el) {
    //     $(el).children().on('click', function (e) {
    //         e.preventDefault();
    //         $(el).children().removeClass('selected');
    //         $(this).addClass('selected');
    //
    //         updateModalOptions();
    //     });
    //
    //     if ($(el).find('.selected').length == 0) {
    //         $(el).children().first().addClass('selected');
    //         updateModalOptions();
    //     }
    // });

    $('.btn_buy').on('click', function () {
        $('h4.value_option').removeClass('danger')
        if (($('.values').length > 0 && $('.values .selected').length == $('.values').length) || $('.values').length == 0) {
            const $form = $('#addToCartForm');
            const data = $form.serialize();

            $.ajax({
                type: 'POST',
                url: $form.attr('action'),
                data,
                dataType: 'json',
                success: function (cart) {
                    updateCart(cart);
                }
            });

            //@todo update options

            $('#productCartModal').modal('show');
        } else {
            $('h4.value_option').addClass('text-danger');
        }
    });

    $('.btn_buy_overview').on('click', function (e) {
        e.preventDefault();

        const $button = $(this);
        const variant = $button.data('variant');

        const data = {
            variant,
            quantity: 1
        };

        $.ajax({
            type: 'POST',
            url: $button.attr('href'),
            data,
            dataType: 'json',
            success: function (cart) {
                updateCart(cart);

                showPopmessage('Toegevoegd aan winkelmand');
            }
        });

        //@todo update options

        //$('#productCartModal').modal('show');
    });
}

function initImageLightbox() {
    $('.images').magnificPopup({
        delegate: 'a',
        type: 'image',
        gallery: {
            enabled: true
        }
    });
}

function initVariants() {
    const $variants = $(document).find('[data-variants]');
    const variants = JSON.parse($variants.text());

    updateOptions(variants);
}

function parseOptions(variants) {
    let options = [];

    variants.forEach(function (variant) {
        variant.options.forEach(function (vo) {
            let co;
            options.forEach(function (option) {
                if (option.name === vo.name) {
                    co = option;
                }
            });
            if (typeof co === 'undefined') {
                co = {
                    id: vo.id,
                    name: vo.name,
                    order: vo.order,
                    values: []
                };
                options.push(co);
            }
            let cov;
            co.values.forEach(function (value) {
                if (value.name === vo.value.name) {
                    cov = value;
                }
            });
            if (typeof cov === 'undefined') {
                cov = {
                    id: vo.value.id,
                    name: vo.value.name,
                    order: vo.value.order
                };
                co.values.push(cov);
            }
            co.values.sort((cov1, cov2) => cov1.order - cov2.order);
        });
    });

    options.sort((co1, co2) => co1.order - co2.order);

    return options;
}

function findCombinations(option, variants, selected) {
    const ids = [];
    variants.forEach(function (variant) {
        const vids = [];
        variant.options.forEach(function (vo) {
            vids.push(vo.value.id);
        });
        let isCombination = false;
        vids.forEach(function (id) {
            if (selected.indexOf(id) !== -1) {
                isCombination = true;
            }
        });
        if (isCombination || vids.length === 1) {
            vids.forEach(function (id) {
                //if (option.values.indexOf(id) === -1) {
                ids.push(id);
                //}
            });
        }
    });
    return ids;
}

function getVariant(variants, selected) {
    let variant = null;
    variants.forEach(function (v) {
        const vids = [];
        v.options.forEach(function (vo) {
            vids.push(vo.value.id);
        });
        if (vids.every((val) => selected.includes(val))) {
            variant = v;
        }
    });
    return variant;
}

function getSelected() {
    const selected = [];
    $('#options').find('.selected').each(function () {
        const id = parseInt($(this).data('id'));
        selected.push(id)
    });
    return selected;
}

function getVariantBySize(variants, s) {
    let variant = null;
    variants.forEach(function (v) {
        v.options.forEach(function (vo) {
            if (vo.value.name == s) {
                variant = v;
            }
        });
    });
    return variant;
}

function getDefaultSelected(variants) {
    const url = new URL(window.location);
    const s = url.searchParams.get('s');

    let variant = null;
    if (null !== s) {
        variant = getVariantBySize(variants, s);
    }

    if (null === variant && variants.length) {
        variant = variants[0];
    }

    const selected = [];
    if (null !== variant) {
        variant.options.forEach(function (vo) {
            selected.push(vo.value.id);
        });
    }
    return selected;
}

function updateOptions(variants, selected = []) {
    if (selected.length === 0) {
        selected = getDefaultSelected(variants);
    }

    const options = parseOptions(variants);
    const variant = getVariant(variants, selected);

    if (null !== variant && (variant.stock > 0)) {
        $('#variant').val(variant.id);

        const $price = $('<div/>', {class: 'price', id: 'selectedVariantPrice'});
        if (null !== variant.discount_price) {
            const $old = $('<span/>', {class: 'old'}).text(variant.price);
            $price.append($old).append(variant.discount_price);
        } else {
            $price.text(variant.price);
        }
        $('#selectedVariantPrice').replaceWith($price);

        $('#selectedVariantSKU').text(variant.sku);
        $('#selectedVariantStock').text(variant.stock);

        $('#selectedVariantDeliveryTime').html(variant.delivery_time);

        $('.delivery_time_visible').fadeOut(500, function () {
            $('.btn_buy').fadeIn(500);
            $('.stock_visible').fadeIn(500);
        });
    } else {
        $('#variant').val('null');

        $('.stock_visible').fadeOut(500);

        $('.btn_buy').fadeOut(500, function () {
            $('.delivery_time_visible').text('Tijdelijk niet te bestellen.').fadeIn(500);
        });
    }

    $('#options').empty();
    options.forEach(function (option) {
        const combinations = findCombinations(option, variants, selected);

        const $option = $('<div/>').append($('<h4/>', {class: 'value_option'}).text('Kies een ' + option.name));
        const $values = $('<div/>', {class: 'values'}).appendTo($option);

        option.values.forEach(function (value) {

            if (selected.indexOf(value.id) !== -1) {
                value.selected = true;
            } else if (combinations.indexOf(value.id) === -1) {
                value.disabled = true;
            }
            const $value = $('<span/>', {
                class: 'value',
                'data-type': option.name,
                'data-id': value.id,
                'data-order': value.order
            })
                .append(value.name)
                .append($('<span/>', {class: 'delivery_time'}).text('Op aanvraag'))
                .appendTo($values);

            if (value.selected) {
                $value.addClass('selected');
            }

            if (value.disabled) {
                $value.addClass('disabled');
            }
        });

        $values.each(function (i, el) {
            $(el).children().on('click', function (e) {
                e.preventDefault();

                const $value = $(this);

                if (!$value.hasClass('disabled')) {
                    $(el).children().removeClass('selected');
                    $value.addClass('selected');
                }

                const selected = getSelected();

                $('#product-size').html('Maat: ' + $value.contents().get(0).nodeValue);

                updateOptions(variants, selected);
            });

            $(el).children().on('mouseenter', function (e) {
                const $value = $(this);

                const id = $value.data('id');
                const selected = getSelected();

                // Currently selected value for option
                const svfo = parseInt($value.parent().find('.selected').data('id'));
                selected[selected.indexOf(svfo)] = id;

                const variant = getVariant(variants, selected);

                $value.find('.delivery_time').text(variant.delivery_time);
                $value.addClass('hover');
            });

            $(el).children().on('mouseleave', function (e) {
                const $value = $(this);

                $value.removeClass('hover');
            });

            if ($(el).find('.selected').length === 0) {
                $(el).children().first().addClass('selected');
            }
        });

        $('#options').append($option);
    });
}
