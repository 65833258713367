$(function () {
    initNav();
    initSlideshow();
    initCarousel();
    initEqualHeights();
    initTabs();
    // initForm();
    initFaq();
    initMatchheight();
    initCookiebox();
    initFavorite();
});

$(window).resize(function () {
    clearTimeout($.data(this, 'resizeTimer'));
    $.data(this, 'resizeTimer', setTimeout(function () {
        initNav();
        initEqualHeights();
    }, 100));
});

$.ajaxSetup({
    headers: {
        [csrf.header]: csrf.token
    }
});

function initSlideshow() {
    $('#slideshow').slick({
        autoplay: true,
        arrows: false
    });
}

function initNav() {
    $('.menu-btn, header nav ul > li, .search-btn, .cart-btn, .user-btn').unbind('click');
    $('header nav ul').removeClass('sub-open').removeAttr('style');
    $('header nav ul li').removeClass('open').removeAttr('style');

    // Navigation
    if ($(window).width() < 992) {

        // Menu
        $('.menu-btn').on('click', function (e) {
            e.preventDefault();

            $(this).toggleClass('active');

            if ($(this).hasClass('active')) {
                $('header nav').slideDown(300, function () {
                    $('header nav ul li').each(function (i, el) {
                        if ($(el).children('ul').length) {
                            var height = $(el).outerHeight() + $(el).children('ul').outerHeight();
                            $(el).attr('data-height', height);
                        }
                    });
                });

                closeSearch($('.search-btn'));
                closeCart($('.cart-btn'));
                closeUser($('.user-btn'));
            } else {
                closeNav($(this));
            }
        });

        $('header nav ul li a').on('click', function (e) {
            var $a = $(this);
            var $li = $a.parent();
            if ($li.children('ul').length) {
                e.preventDefault();
                $li.toggleClass('open');

                if ($li.hasClass('open')) {
                    $li.parent('ul').addClass('sub-open');

                    $('nav > .container > ul, li.open').css('height', $li.data('height'));

                    $('ul.sub-open').each(function (i, el) {
                        $(el).prev('a').addClass('hide');
                    });
                } else {

                    $li.parent('ul').removeAttr('style');
                    $li.parent('ul').prev('a').removeClass('hide');
                    $li.parent('ul').removeClass('sub-open');
                    $li.removeAttr('style');

                    var $last_open_li = $li.closest('li.open');

                    $('nav > .container > ul, li.open').css('height', $last_open_li.data('height'));
                }
            }
        });

        // Search
        $('.search-btn').on('click', function () {
            $(this).toggleClass('active');

            if ($(this).hasClass('active')) {
                $('header .search').slideDown({
                    duration: 300
                }).css({
                    zIndex: 1
                });

                closeNav($('.menu-btn'));
                closeCart($('.cart-btn'));
                closeUser($('.user-btn'));
            } else {
                closeSearch($(this));
            }
        });

        // User
        $('.user-btn').on('click', function () {
            $(this).toggleClass('active');

            if ($(this).hasClass('active')) {
                $('header .user').slideDown({
                    duration: 300
                }).css({
                    zIndex: 1
                });

                closeNav($('.menu-btn'));
                closeCart($('.cart-btn'));
                closeSearch($('.search-btn'));
            } else {
                closeUser($(this));
            }
        });

        // Cart
        $('.cart-btn').on('click', function () {
            $(this).toggleClass('active');

            if ($(this).hasClass('active')) {
                $('header .cart').slideDown({
                    duration: 300
                }).css({
                    zIndex: 1
                });

                closeNav($('.menu-btn'));
                closeSearch($('.search-btn'));
                closeUser($('.user-btn'));
            } else {
                closeCart($(this));
            }
        });
    } else {
        if (navigator.userAgent.match(/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile/i)) {
            $('header nav ul li').each(function (i, el) {
                let $el = $(el);
                $el.children('a').click(function(e){
                    if(!$el.hasClass('clicked') && $el.has('ul').length > 0) {
                        e.preventDefault();
                        $el.addClass('clicked');
                    }
                });
            });
        }

        // User
        $('.user-btn').on('click', function () {
            $(this).toggleClass('active');
            $('header .user').fadeToggle(100);
        });

        $(document).mouseup(function (e) {
            var container = $('.user-btn, .user');
            if (!container.is(e.target) && container.has(e.target).length === 0) {
                $('.user').hide();
                $('.user-btn').removeClass('active');
            }
        });
    }
}

function closeNav(e) {
    e.removeClass('active');

    $('header nav').slideUp({
        duration: 300
    }).css({
        zIndex: 0
    });

    $('header nav ul').removeClass('sub-open').removeAttr('style');
    $('header nav ul li').removeClass('open').removeAttr('style');
}

function closeSearch(e) {
    e.removeClass('active');

    $('header .search').slideUp({
        duration: 300
    }).css({
        zIndex: 0
    });
}

function closeUser(e) {
    e.removeClass('active');

    $('header .user').slideUp({
        duration: 300
    }).css({
        zIndex: 0
    });
}

function closeCart(e) {
    e.removeClass('active');

    $('header .cart').slideUp({
        duration: 300
    }).css({
        zIndex: 0
    });
}

function initCarousel() {
    $('#carousel').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 7000,
        arrows: false
    });
}

function initEqualHeights() {
    if ($('.equalHeights').length) {
        $('.equalHeights').each(function () {
            var parent = $(this),
                items = parent.data('equals').replace(/ /g, '').split(','),
                height;

            items.forEach(function (item) {
                height = 0;

                parent.find(item).each(function () {
                    if ($(this).height() > height) {
                        height = $(this).height();
                    }
                });

                if (parent.hasClass('no-mobile') && $(window).width() < 767) {
                    // Do nothing...
                } else {
                    parent.find(item).css({
                        minHeight: height
                    });
                }
            });
        });
    }
}

function initTabs() {
    if ($('.tabs').length > 0) {
        $('.tabs nav ul .tab').each(function () {
            $(this).click(function () {
                $('.tabs nav ul .tab.active').removeClass('active');
                $(this).addClass('active');

                if ($(this).hasClass('active')) {
                    $('.tabs .tab-contents .tab-content.active').removeClass('active');
                    $('#' + $(this).data('tab')).addClass('active');
                }
            });
        });
    }
}

function initForm() {
    if ($('form.form').length) {
        $('form.form .form-group').each(function () {
            if ($(this).find('input, textarea').val() != '') {
                $(this).addClass('active');
            }

            $('input, textarea').focus(function () {
                $(this).parents('.form-group').addClass('active');
            }).focusout(function () {
                if ($(this).val() == '') {
                    $(this).parents('.form-group').removeClass('active');
                }
            });

            if ($(this).find('input, textarea').hasClass('required')) {
                $(this).find('label').html($(this).find('label').text() + ' <span>*</span>');
            }
        });
    }
}

function initFaq() {
    if ($('#faq').length > 0) {
        $('.question').on('click', function () {
            $(this).siblings().removeClass('show');
            $(this).toggleClass('show');
        });
    }
}

function initMatchheight() {
    $('.matchheight').matchHeight();
}

function initCookiebox() {
    if ($('#cookiebox').length && document.cookie.indexOf('cookiebox=') === -1) {
        setTimeout(function () {
            $('#cookiebox').hide().fadeIn('fast').css({
                bottom: 30
            });
        }, 500);

        $('#cookiebox .accept').click(function () {
            setCookie('cookiebox', 1, 365);
            $('#cookiebox').fadeOut().css({
                bottom: -230
            });
        });
    }
}

function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(name) {
    var value = "; " + document.cookie;
    var parts = value.split("; " + name + "=");
    if (parts.length == 2) return parts.pop().split(";").shift();
}

function removeCookie(name) {
    if (get_cookie(name)) {
        document.cookie = name + '=; Max-Age=-99999999;';
    }
}

function initFavorite() {
    $('.product .favorite').on('click', function (e) {
        e.preventDefault();

        const $button = $(this);
        const id = $button.data('product');

        const data = {
            id
        };

        $.ajax({
            type: 'POST',
            url: $button.attr('href'),
            data,
            dataType: 'json',
            success: function (favorites) {
                if (typeof favorites[id] === 'undefined') {
                    if($('#products').hasClass('favorites')){
                        $button.parents('.product').fadeOut();

                        console.log($('.product').length);

                        if(favorites.length == 0) {
                            $('<div class="alert alert-info" style="margin-top: 30px;">Er zijn momenteel geen favorieten.</div>').appendTo($('.page-content'));
                        }
                    } else {
                        $button.removeClass('active')
                    }
                } else {
                    $button.addClass('active')
                }
            }
        });
    });
}

function showPopmessage(text) {
    $('.popmessage').text(text).addClass('active');

    setTimeout(function(){
        $('.popmessage').removeClass('active');
    }, 3000);
}